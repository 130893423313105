import React, { useState } from "react"

import { graphql, Link } from "gatsby"
import { inject, observer } from "mobx-react"

import { SEO } from "../../components/base/seo/seo"
import { Wrapper } from "../../components/base/wrapper/wrapper"
import { AccordionCard } from "../../components/modules/accordion/accordion"
import { NoResults } from "../../components/modules/no-results/no-results"

export default inject("regionStore")(
  observer(({ data, regionStore }) => {
    const { unmappedFactSheets, page } = data.wagtail

    const [factSheetFilter, setFactSheetFilter] = useState()

    const factSheets = unmappedFactSheets
      .filter(value => value["__typename"] === "FactSheet")
      .filter(({ validInEngland, validInScotland }) =>
        regionStore.currentRegion === "EW" ? validInEngland : validInScotland
      )
      .filter(({ title, keywords }) => {
        keywords = keywords ? keywords.split(", ") : []

        const searchTerms = [title, ...keywords].map(term => term.toLowerCase())

        return factSheetFilter
          ? searchTerms.some(q => q.indexOf(factSheetFilter.toLowerCase()) >= 0)
          : (title, keywords)
      })
      .map(({ title, url, factSheetCategories, description }) => ({
        url,
        label: title,
        description,
        category: factSheetCategories.map(
          element => element["category"]["name"]
        ),
      }))
      .filter(({ category }) => !category.includes("Hidden"))
      .sort((a, b) => {
        const textA = a.label.toUpperCase()
        const textB = b.label.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })

    const categories = [
      ...new Set([].concat(...factSheets.map(({ category }) => category))),
    ].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))

    const handler = ({ target }) => {
      setFactSheetFilter(target.value.toLowerCase())
    }

    return (
      <Wrapper hasFeedbackForm={true}>
        <SEO
          title={page.seoTitle}
          description={page.description}
          canonical={page.canonicalUrl}
        />

        <h1>Our fact sheet library</h1>

        <div
          dangerouslySetInnerHTML={{
            __html: page.body
              .replace(/<b>/g, "<strong>")
              .replace(/<\/b>/g, "</strong>"),
          }}
        />

        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <div className="form-group">
            <label htmlFor="factSheetSearchInput">Search</label>
            <input
              type="search"
              className="form-control"
              id="factSheetSearchInput"
              aria-describedby="factSheetHelp"
              placeholder="Enter search terms"
              onChange={handler}
            />
            <small id="factSheetHelp" className="form-text text-muted">
              Use this input to search through our fact sheets.
            </small>
          </div>
        </form>

        {factSheetFilter ? (
          <div>
            <h2 className="h3">Search Results</h2>

            {!factSheets.length && <NoResults query={factSheetFilter} />}

            <ul className="list-group list-group-flush">
              {factSheets.map(({ label, url, description }) => (
                <li key={label} className="list-group-item">
                  <Link to={url}>{label}</Link>
                  <p className="accordion-desc">{description}</p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          categories.length > 0 && (
            <AccordionCard
              categories={categories}
              data={factSheets}
              initialSelection={0}
            />
          )
        )}
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on FactSheetLibraryPage {
          seoTitle
          title
          description
          canonicalUrl
          body
        }
      }
      unmappedFactSheets: pages {
        ... on FactSheet {
          title
          url
          validInEngland
          validInScotland
          description
          keywords
          factSheetCategories {
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`
