import React, { useState } from "react"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import { observer } from "mobx-react"

import { convertStringToCamelcase } from "../../../helpers/helpers"

import "./accordion.scss"

export const AccordionCard = observer(
  ({ categories, data, initialSelection }) => {
    const [activeCategory, setActiveCategory] = useState(
      categories[initialSelection]
    )

    const onHeaderClick = category => {
      const isActiveCategory = category === activeCategory
      setActiveCategory(isActiveCategory ? undefined : category)
      if (!isActiveCategory) {
        setTimeout(() => {
          document
            .getElementById(`${convertStringToCamelcase(category)}`)
            .scrollIntoView()
        }, 200)
      }
    }

    return categories.map(category => {
      const id = convertStringToCamelcase(category)
      return (
        <div id={id} key={category} className="accordion card">
          <button
            className={`
              accordion-header 
              card-header 
              ${activeCategory === category ? "open" : ""}
            `}
            onClick={() => onHeaderClick(category)}
            data-toggle="collapse"
            data-target={`${id}-collapse`}
            aria-expanded={activeCategory === category}
          >
            <h2 id={`${id}-heading`} className="mb-0 h5 accordion-header-title">
              {category}
            </h2>
          </button>

          <div
            id={`${id}-collapse`}
            className={`
              collapse 
              ${activeCategory === category ? "show" : ""}
            `}
            aria-labelledby={`${id}-heading`}
            data-parent="#accordion"
          >
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {data
                  .filter(
                    item => item.category && item.category.includes(category)
                  )
                  .map(({ label, url, description }) => (
                    <li key={label} className="list-group-item">
                      <Link to={url} className="accordion-link">
                        {label}
                      </Link>
                      <p className="accordion-desc">{description}</p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )
    })
  }
)

AccordionCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
    })
  ),
  initialSelection: PropTypes.number,
}
