import React from "react"

import PropTypes from "prop-types"

import { triggerWebchat } from "../../../helpers/helpers.js"

import "./no-results.scss"

export const NoResults = ({ query }) => {
  const onWebchatClick = e => {
    e.preventDefault()
    triggerWebchat()
  }

  return (
    <div className="alert alert-secondary p-3 p-sm-4 no-results" role="alert">
      <h3 className="alert-heading mb-2 mb-sm-3">No results for "{query}"</h3>

      <hr />

      <ul>
        <li>Try using more general words and check your spelling.</li>
        <li>
          Make sure you are in the right jurisdiction of the website (England
          and Wales, or Scotland).
        </li>
      </ul>

      <p>
        If you still can’t find what you need you can&nbsp;
        <a href="#" className="no-results-link" onClick={onWebchatClick}>
          webchat
        </a>
        &nbsp;with one of our advisers.
      </p>
    </div>
  )
}

NoResults.propTypes = {
  query: PropTypes.string,
}
